

.radar-container {
   justify-content: center;
   display: flex;
   margin: auto;
   width: 50vw;
}

.outer-container {
    margin: auto;
}